<template>
  <div class="admin-posts">
    <h2 class="text--dark" style="margin-bottom: 30px">Inlägg</h2>
    <div class="f-c f-ac" style="width: 100%">
      <router-link to="/admin/posts/write"><button class="button">Nytt inlägg</button></router-link>
      <div class="posts-list shadow">
        <div v-for="post in posts" :key="post.slug" style="width: 100%">
          <div class="f-r f-ac" style="width: 100%">
            <post-list-entry :post="post" :admin="true" />
            <h3 class="el-icon-delete-solid hoverable" style="color: var(--c-primary); margin-left: 20px" @click="deletePost(post)"></h3>
          </div>
          <div class="divider"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostListEntry from '../../components/PostListEntry.vue'
export default {
  components: { PostListEntry },
  computed: {
    posts() {
      var keys = Object.keys(this.$store.state.posts)

      keys.sort((a, b) => {
        if (a < b) return 1 
        if (a > b) return -1
        return 0
      }) 

      const posts = {}
      for(const key of keys) posts[key] = this.$store.state.posts[key]

      return posts
    }
  },
  methods: {
    async deletePost(data) {
      this.$confirm('Är du säker på att du vill ta bort inlägget?', 'Ta bort inlägg', {
        confirmButtonText: 'Ta bort',
        cancelButtonText: 'Avbryt',
      }).then(() => {
        this.$store.dispatch('deletePost', { data })
      })
    }
  }
}
</script>

<style scoped>

:root {
  --el-color-primary: green;
}

.admin-posts {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  max-width: 650px;
}

.posts-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--c-background);
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

  width: 100%;
  box-sizing: border-box;
}

</style>